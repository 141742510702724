<template>
  <!-- 指定签署位置 文件id相同 页码相同时显示 -->
  <!-- 0 签章，1 签名，2 日期，3 骑缝章 -->
  <!-- 签章 -->
  <template v-for="(pel, pind) in state.sealpositionDatas" :key="pind">
    <template v-if="props.basefilesList[state.basefilesIndex].id == pel.fileId && pel.pageIndex == props.ind + 1">
      <div class="sealpoint point pointac pt-a"
        :style="[{ width: (pel.width/600*800) + 'px' }, { height: (pel.height/600*800) + 'px' }, { 'left': pel.signatureX + '%' }, { 'top': (((pel.signatureY * props.pageHeight / 100 - (pel.height/600*800))/props.pageHeight) * 100).toFixed(2) + '%' }]"
        v-if="pel.nowSignData">
        <img :src="pel.nowSignData.url" class="w100 ">
        <img class="del" src="@/assets/img/general/del.png" @click="pel.nowSignData = false,store.commit('sign/setcheckTime',new Date())">
      </div>
      <div class="point pt-a" v-else
        :style="[{ width: (pel.width/600*800) + 'px' }, { height: (pel.height/600*800) + 'px' }, { 'left': pel.signatureX + '%' }, { 'top': (((pel.signatureY * props.pageHeight / 100 - (pel.height/600*800))/props.pageHeight) * 100).toFixed(2) + '%' }]">
        {{
          pel.name
        }}</div>
    </template>
  </template>
  <!-- 签名 -->
  <template v-for="(pel, pind) in state.signaturepositionDatas" :key="pind">
    <template v-if="props.basefilesList[state.basefilesIndex].id == pel.fileId && pel.pageIndex == props.ind + 1">
      <div class="signaturepoint point pointac pt-a"
        :style="[{ width: pel.nowSignData.width + 'px' }, { height: pel.nowSignData.height + 'px' }, { 'left': pel.signatureX + '%' }, { 'top': (((pel.signatureY * props.pageHeight / 100 - (pel.height/600*800))/props.pageHeight) * 100).toFixed(2) + '%' }]"
        v-if="pel.nowSignData">
        <img :src="pel.nowSignData.url" class="w100 h100">
        <img class="del" src="@/assets/img/general/del.png" @click="pel.nowSignData = false,store.commit('sign/setcheckTime',new Date())">
      </div>
      <div class="point pt-a" v-else
        :style="[{ width: (pel.width/600*800) + 'px' }, { height: (pel.height/600*800) + 'px' }, { 'left': pel.signatureX + '%' }, { 'top': (((pel.signatureY * props.pageHeight / 100 - (pel.height/600*800))/props.pageHeight) * 100).toFixed(2) + '%' }]">
        {{
          pel.name }}
      </div>
    </template>
  </template>
  <!-- 日期 -->
  <template v-for="(pel, pind) in state.datepositionDatas" :key="pind">
    <template v-if="props.basefilesList[state.basefilesIndex].id == pel.fileId && pel.pageIndex == props.ind + 1">
      <div class="timepoint point pointac pt-a" v-if="pel.nowSignData"
        :style="[{ width: pel.nowSignData.width + 'px' }, { height: pel.nowSignData.height + 'px' }, { 'left': pel.signatureX + '%' }, { 'top': pel.signatureY + '%' }]">
        {{ pel.nowSignData.name }}
        <img class="del" src="@/assets/img/general/del.png" @click="pel.nowSignData = false,store.commit('sign/setcheckTime',new Date())">
      </div>
      <div class="point pt-a" v-else
        :style="[{ width: (pel.width/600*800) + 'px' }, { height: (pel.height/600*800) + 'px' }, { 'left': pel.signatureX + '%' }, { 'top': pel.signatureY + '%' }]">
        {{ pel.name }}
      </div>
    </template>
  </template>
</template>
<script setup>
import { ref, unref, computed, watch, reactive, onMounted, nextTick } from "vue";
import { useStore } from "vuex";
const store = useStore();
const props = defineProps({
  basefilesList: {
    type: Array,
  },//所有文件数据
  ind: {
    type: Number
  },//当前文件下标
  pageHeight: {
    type: Number,
    requried: true
  }
});
const state = reactive({
  basefilesIndex: computed(() => {
    return store.state.sign.basefilesIndex
  }),//当前展示文件下标
  //当前签署人所有指定签署位置
  sealpositionDatas: computed(() => {
    return store.state.sign.sealpositionDatas//签章
  }),
  signaturepositionDatas: computed(() => {
    return store.state.sign.signaturepositionDatas//签名
  }),
  datepositionDatas: computed(() => {
    return store.state.sign.datepositionDatas//日期
  }),
});
</script>
<style lang="scss" scoped>
.point {
  border: 1px dashed rgba(255, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  top: 0;
}

.pointac {
  // background: #FFFFFF;
  border: 1px solid #E6E6E6;

  .del {
    display: none;
    cursor: pointer;
    width: 16px;
    height: 16px;
    position: absolute;
    top: -8px;
    right: -8px;
  }
}

.pointac:hover {
  z-index: 999;
  .del {
    display: block;
  }
}

.timepoint {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
}
</style>